import React, { useState } from "react"
import contactBg from "../assets/img/contact-bg.jpg"
import { Form, Button, TextArea, Input } from "semantic-ui-react"
import axios from "axios"
import "../css/contact.css"

const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [messageSent, setMessageSent] = useState(false)

  const sendEmail = () => {
    console.log("sendEmail")
    axios
      .post("http://mpaccione.com/mail", {
        name,
        email,
        message,
      })
      .then(res => {
        console.log({ res })
        setName("")
        setEmail("")
        setMessage("")
        setMessageSent(true)
      })
      .catch(err => {
        console.log({ err })
        alert(err)
        setMessageSent(false)
      })
  }

  return (
    <div id="contact-form-wrap">
      <div id="contact-form-inner-wrap">
        {!messageSent ? (
          <h1>
            Let's get your project started,<br></br>contact us now!
          </h1>
        ) : (
          <h1>Message Sent!</h1>
        )}
        <Form
          onSubmit={() => {
            sendEmail()
          }}
        >
          <div style={{ display: "flex" }}>
            <Form.Field>
              <Input
                type="text"
                placeholder="Name"
                required
                onChange={e => {
                  setName(e.target.value)
                }}
                style={{
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="email"
                placeholder="Email"
                required
                onChange={e => {
                  setEmail(e.target.value)
                }}
                style={{
                  borderBottomLeftRadius: "0px",
                  borderTopLeftRadius: "0px",
                }}
              />
            </Form.Field>
          </div>
          <Form.Field>
            <TextArea
              placeholder="Message"
              required
              onChange={e => {
                setMessage(e.target.value)
              }}
            />
          </Form.Field>
          <Button type="submit" style={{ margin: "auto", display: "block" }}>
            Send
          </Button>
        </Form>
      </div>
      <div className="contact-cover"></div>
      <div
        className="contact"
        style={{ backgroundImage: `url(${contactBg})` }}
      ></div>
    </div>
  )
}

export default Contact
